<template>
    <div class="navmb">
        <div class="flex flexa">
            <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
                <Back />
            </el-icon> &nbsp; &nbsp;
            <span class="">服务请求详情</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="bgf padd20" v-loading.lock="fullscreenLoading">
        <div class="top" :class="scroll >= 20 ? 'fixed' : ''">
            <p class="col24 font20 fontw ww100 padr20 box">{{ info.problemProfile }} </p>
            <div class="flex flexa">
                <span class="point">
                    <!-- <img v-if="info.isCollection==1" :src="utils.loadImg('xing-a.png')" alt=""  /> 
                    <img v-else :src="utils.loadImg('xing.png')" alt=""  />  -->
                    <el-button v-if="info.isCollect == 0" link :disabled="info.disableLoading ?? false"
                        @click="collectOrder(info)">
                        <template #icon>
                            <el-icon size="20">
                                <Star />
                            </el-icon>
                        </template>
                    </el-button>
                    <el-button v-else-if="info.isCollect == 1" link :disabled="info.disableLoading ?? false"
                        @click="collectOrder(info)">
                        <template #icon>
                            <el-icon color="#ff6a00" size="20">
                                <StarFilled />
                            </el-icon>
                        </template>
                    </el-button>
                </span>
                <span class="col24 font12 padl30">上次更新时间: {{ info.lastUpdateTime }} </span>
            </div>
        </div>
        <div class="flexab">
            <div class="font12 padr20 flex flexw">
                <span class="colb1 mart20">当前工单状态：</span>
                <span class="colblue marr40 mart20">
                    {{ userStatus.get(info.userStatus) }}
                </span>
                <span class="colb1 mart20">工单编号：</span>
                <span class="col24 marr40 mart20">{{ info.outTradeNo }} </span>
                <div class="flex" v-if="info.type == 2">
                    <span class="colb1 mart20">严重等级：</span>
                    <span style="margin-top:25px" :class="['coll' + info.u, 'dian']"></span>
                    <span class="marl6 col24 marr10 mart20">{{ u[info.u] }}</span>
                    <span @click="upgrade.visible = true" class="colblue mat-1 underline marr40 mart20">等级调整</span>
                </div>
                <span class="colb1 mart20">升级状态：</span>
                <span class="colzhuti mart20"> {{ upgradeStatus.get(info.upgradeStatus) }}</span>&nbsp;&nbsp;
                <span v-if="info.upgradeStatus == 1" @click="info.upgradeReason_visible = true"
                    class="colblue underline point mart20">查看升级原因 ></span>
            </div>
            <div class="nowrap mart20">
                <el-button v-if="info.satisfaction == 0 && [10, 11].includes(info.userStatus)" type="primary"
                    @click="comment.show = true">服务评价</el-button>
                <!-- 错误的修改 -->
                <el-button v-if="info.openAgain == 1" @click="reOpen.show = true">重开工单</el-button>
                <el-button v-if="info.upgradeStatus == 0 && (![9, 10, 11].includes(info.userStatus))"
                    @click="upgrade_order.visible = true">工单升级</el-button>
                <template v-if="(![10, 11].includes(info.userStatus))">
                    <el-button v-if="![9,10].includes(info.userStatus)" @click="finish.show = true">结单</el-button>
                    <el-button
                        @click="attachment.visible = true; fileList.attachment = []; attachment.form.attachmentPath = ''"
                        type="primary">+添加附件</el-button>
                    <el-button @click="feedback.visible = true" type="primary">+添加反馈</el-button>
                </template>
            </div>
        </div>
    </div>
    <p class="h20"></p>
    <div class="flex flexas">
        <div class="left" v-loading="record.loading">
            <div class="bgec flexab pad20 col24 flexw">
                <!-- <span class="font14 nowrap padr30">沟通记录</span> -->
                <ul class="g-tabsb1">
                    <li :class="{ active: record.param.showIsType == 1 }" @click="toRecord">
                        <div class="con">沟通记录</div>
                    </li>
                    <li :class="{ active: record.param.showIsType == 2 }" @click="toOperation">
                        <div class="con">操作记录</div>
                    </li>
                </ul>
                <div class="font12 flex flexa mart20 marb20">
                    <label class="inbl w68">时间排序：</label>
                    <el-select class="w150 marr20" v-model="record.param.sort" @change="getOrderRecordListDto()"
                        clearable placeholder="请选择">
                        <el-option label="时间升序" value="1"></el-option>
                        <el-option label="时间降序" value="2"></el-option>
                    </el-select>
                    <div v-show="record.param.showIsType == 1">
                        <label class="inbl w68">筛选依据：</label>
                        <el-select class="w150" v-model="record.param.codeFlag" @change="getOrderRecordListDto()"
                            clearable placeholder="请选择">
                            <el-option v-for="item in code" :key="item[0]" :label="item[1]" :value="item[0]">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <KeepAlive>
                <div style="overflow:auto">
                    <!-- 沟通记录显示 -->
                    <template v-if="record.param.showIsType == 1">
                        <div class="bgf marb10 padd20" v-for="(item, index) in record.list" :key="index">
                            <template v-if="item.codeFlag == 1">
                                <!-- 客户问题描述 -->
                                <div class="flex flexa">
                                    <img :src="item.headPic" alt="" width="40" height="40" class="borahalf" srcset="">
                                    <p class="col6 padl15">{{ item.username }} {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ code.get(item.codeFlag) }}】 </p>
                                </div>
                                <div class="padl55 ">
                                    <p class="col2 lh16 padb7"><span class="col0 fontw6 nowrap">【问题概要】</span>{{
                info.problemProfile }} </p>
                                    <p class="col2 lh16 padb7 flex"><span class="fontw6 col0 nowrap">【问题描述】</span> <span
                                            v-html="item.content"></span> </p>
                                    <div v-if="item.descImage?.length > 0" class="padt10 padb16">
                                        <p class="fontw6 col0 padb14">【问题截图】</p>
                                        <div class="border padt40 padb40 padl70 padr40">
                                            <el-image v-for="(ite, index) in item.descImage ?? [{}]" :key="index"
                                                style="width: 30%; margin-right:3%;" :src="ite"
                                                :preview-src-list="item.descImage">
                                            </el-image>
                                        </div>
                                    </div>
                                    <template v-if="item.attachmentPath?.length">
                                        <div class="col2 lh20 padb7 flex">
                                            <span class="col0 fontw6" style="width:70px">【附件】</span>
                                            <div>
                                                <p v-for="(item, index) in item.attachmentPath" :key="index"
                                                    @click="downloadtxt(item)" class="colblue point ">
                                                    {{ decodeURIComponent(item.split('/').pop()) }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="item.recodeType == 1">
                                <div class="flex flexa">
                                    <img :src="item.headPic" alt="" width="40" height="40" class="borahalf" srcset="">
                                    <p class="col6 padl15">{{ item.username }} {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ (item.type==3||item.type==5||item.type==6||item.type==7||item.type==8||item.type==11)?typeL[item.type]:code.get(item.codeFlag) }}】 </p>
                                </div>
                                <div class="padl55 ">
                                    <p v-html="item.content"></p>
                                </div>
                            </template>
                            <!-- 客户 -->
                            <template v-else-if="item.recodeType == 2">
                                <div class="flex flexa">
                                    <img :src="item.engineerHeadPic" alt="" width="40" height="40" class="borahalf"
                                        srcset="">
                                    <!-- <img :src="item.engineerHeadPic" alt="" width="40" height="40" class="borahalf"
                                        srcset=""> -->
                                    <p class="col6 padl15">海量技术支持中心 {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ (item.type==3||item.type==5||item.type==6||item.type==7||item.type==8||item.type==11)?typeL[item.type]:code.get(item.codeFlag) }}】</p>
                                    <!-- <p class="col6 padl15 fontw6">{{ item.engineerName }} {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ code.get(item.codeFlag) }}】 </p> -->
                                </div>
                                <div class="padl55 ">
                                    <p v-html="item.content"></p>
                                </div>
                                <template v-if="item.attachmentPath?.length">
                                    <div class="padl55 mart10 col2 lh20 padb7 flex">
                                        <span class="col0 fontw6" style="width:70px">【附件】</span>
                                        <div>
                                            <p v-for="(item, index) in item.attachmentPath" :key="index"
                                                @click="downloadtxt(item)" class="colblue point ">
                                                {{ decodeURIComponent(item.split('/').pop()) }}
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </template>
                    <!-- 操作记录显示 -->
                    <template v-else-if="record.param.showIsType == 2">
                        <div class="bgf marb10 padd20" v-for="(item, index) in record.list" :key="index">
                            <!-- 用户操作 -->
                            <template v-if="item.recodeType == 1">
                                <div class="flex flexa">
                                    <img :src="item.headPic" alt="" width="40" height="40" class="borahalf" srcset="">
                                    <p class="col6 padl15 fontw6">{{ item.username }} {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ recordType.get(item.type) }}】 </p>
                                </div>
                                <div class="padl55 " v-html="item.content">
                                </div>
                            </template>
                            <!-- 工程师操作 -->
                            <template v-else-if="item.recodeType == 2">
                                <div class="flex flexa">
                                    <img :src="item.engineerHeadPic" alt="" width="40" height="40" class="borahalf"
                                        srcset="">
                                    <p class="col6 padl15 fontw6">{{ item.engineerName }} {{ item.createTime }}
                                        &nbsp;&nbsp;【{{ recordType.get(item.type) }}】 </p>
                                </div>
                                <div class="padl55 " v-html="item.content">
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </KeepAlive>
        </div>

        <div class="right">
            <p class="col24 font14 padb20">工单概要</p>
            <div class="flex font12 padb30 ">
                <p class="col9a nowrap">问题概要：</p>
                <p class="col2 lh16">{{ info.problemProfile }} </p>
            </div>
            <ul class="ul1">
                <li> <span>客户服务号</span>{{ info.csi }} </li>
                <li> <span>客户名称</span>{{ info.companyName }} </li>
                <li> <span>用户ID</span>{{ info.userId }}</li>
                <li> <span>工单联系人</span>{{ info.mainContact }}
                    <span v-if="info.userStatus != 10" class="marl6 colzhuti point"
                        @click="editContact(1, '编辑联系人信息')">编辑</span></li>
                <li v-if="info.standbyContact && info.type == 2"> <span>备用联系人</span>{{ info.standbyContact }}<span
                        class="marl6 colzhuti point" @click="editContact(2, '编辑备用联系人信息')">编辑</span></li>
                <li> <span>查阅权限</span>{{ isOpen.get(info.isOpen) }}<span v-if="info.userStatus != 10" class="marl6 colzhuti point"
                        @click="editIsOpen()">编辑</span></li>
                <li> <span>首选联系方式</span>{{ mode.get(info.mode) }}</li>

            </ul>
            <ul class="ul1">
                <li v-if="info.type == 2"> <span>严重等级</span> <span :class="['coll' + info.u, 'dian']"></span><span
                        class="marl6">{{ u[info.u] }}</span></li>
                <li> <span>状态</span>{{ userStatus.get(info.userStatus) }}</li>
                <li> <span>升级状态</span>{{ upgradeStatus.get(info.upgradeStatus) }}</li>
                <li> <span>创建时间</span>{{ info.createTime }} </li>
                <li> <span>上次更新时间</span>{{ info.lastUpdateTime }}</li>
                <li> <span>工单类型</span>{{ type.get(info.type) }}</li>
            </ul>
            <ul class="ul1">
                <li>
                    <span>相关附件</span>
                    <span @click="info.attachment_visible = true" v-if="info.attachmentListDtos?.length"
                        class="colzhuti point">查看相关附件</span>
                    <span v-else>无相关附件</span>
                </li>
                <li>
                    <span>相关链接</span>
                    <span v-if="info.linkListDtos?.length" @click="links = true"
                        class="colzhuti point">查看相关链接</span>
                    <span v-else>无相关链接</span>
                </li>
                <li>
                    <span>相关知识文档</span>
                    <span v-if="info.docListDtos?.length > 0" @click="openfiles(1)"
                        class="colzhuti point">查看相关知识文档</span>
                    <!-- <span v-if="info.knowledgeDocId.length>0" @click="files=true" class="colzhuti point">查看相关知识文档</span> -->
                    <span v-else>无相关知识文档</span>
                </li>
                <li>
                    <span>相关Bug文档</span>
                    <span v-if="info.bugListDtos?.length" @click="openfiles(2)" class="colzhuti point">查看相关Bug文档</span>
                    <!-- <span v-if="info.knowLedgeBugDocId.length>0" @click="files=true" class="colzhuti point">查看相关Bug文档</span> -->
                    <span v-else>无相关Bug文档</span>
                </li>
            </ul>
            <ul class="ul1">
                <template v-if="info.type == 2">
                    <li> <span>硬件平台</span>{{ info.hardwarePlatformLabel || '- -' }} 
                        <!-- <span v-if="info.userStatus != 10" class="marl6 colzhuti point"
                            @click="clickEdit(1, '硬件平台')">编辑</span> -->
                        </li>
                    <li> <span>操作系统版本</span>{{ info.operatingSystemName || '- -' }} / {{ info.operatingSystemVersionName || '- -' }}
                        <!-- <span v-if="info.userStatus != 10" class="marl6 colzhuti point"
                        @click="clickEdit(2, '操作系统版本')">编辑</span> -->
                    </li>
                    <li> <span>操作系统语言</span>{{ info.operatingSystemLanguageLabel || '- -' }}
                        <!-- <span v-if="info.userStatus != 10" class="marl6 colzhuti point"
                        @click="clickEdit(3, '操作语言')">编辑</span> -->
                    </li>
                </template>
                <li> <span>产品名称</span>{{ info.productName }}
                    <!-- <span class="marl6 colzhuti point" v-if="info.userStatus != 10"
                    @click="clickEdit(4, '产品名称')">编辑</span> -->
                </li>
                <li v-if="info.type == 2"> <span>产品版本</span>{{ info.productVersionName }}
                    <!-- <span class="marl6 colzhuti point" v-if="info.userStatus != 10"
                    @click="clickEdit(5, '产品版本')">编辑</span> -->
                </li>
                <li> <span>问题类型</span>{{ info.productModelName }}
                    <!-- <span class="marl6 colzhuti point" v-if="info.userStatus != 10"
                    @click="clickEdit(6, '问题类型')">编辑</span> -->
                </li>
                <template v-if="info.type == 2">
                    <li> <span>部署方式</span>{{ info.deploymentWayLabel }}
                        <!-- <span class="marl6 colzhuti point" v-if="info.userStatus != 10"
                        @click="clickEdit(7, '部署方式')">编辑</span> -->
                    </li>
                    <li> <span>是否海量数据库一体机</span>{{ oneMachine.get(info.oneMachine) }}
                        <!-- <span class="marl6 colzhuti point" v-if="info.userStatus != 10"
                        @click="clickEdit(8, '是否海量数据库一体机')">编辑</span> -->
                    </li>
                </template>
            </ul>
        </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="团队内工单查阅权限" width="900px" align-center v-model="isOpenLook.visible"
        @closed="$refs.submitForm_IsOpen.resetFields()">
        <el-form v-loading="isOpenLook.loading" :model="isOpenLook.form" ref="submitForm_IsOpen" label-width="auto"
            :rules="isOpenLook.rule">
            <el-form-item prop="isOpen" label="是否全部公开">
                <el-radio-group v-model="isOpenLook.form.isOpen">
                    <el-radio :value="1">是</el-radio>
                    <el-radio :value="2">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="isOpenLook.form.isOpen == 2" prop="departmentId" label="公开的团队部门">
                <el-select v-model="isOpenLook.form.departmentId" size="large" multiple collapse-tags
                    :max-collapse-tags="2" collapse-tags-tooltip placeholder="请选择" style="width: 340px">
                    <el-option v-for="item in vselect.team.map_options.get(info.csiId)" :key="item.id"
                        :label="item.departmentTitle" :value="item.id" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="isOpenLook.visible = false">取 消</el-button>
            <el-button type="primary" @click="submitIsOpen">提 交</el-button>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="添加工单附件" width="700px" v-model="attachment.visible">
        <ul class="sylform" v-loading="attachment.loading">
            <li class="li1">
                <label for="">问题附件</label>
                <div>
                    <ul class="ulfile">
                        <li v-for="(item, index) in fileList.attachment" :key="index" class="">
                            <span class="col63">{{ item.name }}</span>
                            <span @click="fileList.attachment.splice(index, 1)" class="colred point">删除</span>
                        </li>
                    </ul>
                    <div class="positionr inbl">
                        <!-- <el-upload
                            v-model:file-list="fileList.attachment"
                            :ref="el => fileList['instance_attachment'] = el"
                            :headers="{ token: $store.state.token }"
                            :data="{
                                id: $route.params.id,
                                type: 3
                            }"
                            action="/api/user/upload/ossUploadList"
                            class="upload-demo"
                            multiple
                            :show-file-list="false"
                            name="multipartFile"
                            :on-success="(resp, file, files) => {
                                if (resp.status == 200) {
                                    file.url = utils.Decrypt(resp.data).path;
                                    attachment.form['attachmentPath'] = files.map(item => item.url).filter(item => item).join(',')
                                }
                            }"
                            > -->
                            <el-upload
                            v-model:file-list="fileList.attachment"
                            action="#"
                            class="upload-demo"
                            :http-request="hadhttprequest"
                            multiple
                            :show-file-list="false"
                            name="file"
                            >
                            <el-button type="primary">添加附件</el-button>
                        </el-upload>
                    </div>
                    <p class="col8f font12 padt4">支持.png、.jpg、.jpeg、.txt、.rar、.doc、.xls、.xlsx、.zip格式，最大不超过50M</p>
                </div>
            </li>
            <li class="li1">
                <label for="">附件注释</label>
                <el-input v-model="attachment.form.attachmentDescription" type="textarea" :rows="6"
                    placeholder="请输入添加附件的注释" class="w515" size="small"></el-input>
            </li>
            <li>
                <label for=""></label>
                <div class="tl padt20">
                    <el-button @click="attachment.visible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAttachment">提 交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="添加工单反馈" width="900px" align-center v-model="feedback.visible">
        <ul class="sylform">
            <li class="li1">
                <label for="">工单反馈<em>*</em></label>
                <div style="box-sizing: border-box; border: 1px solid #dfe2e8;z-index: 500;">
                    <kindeditor
                        :uploadParams="{
                            type: 3,
                            id: $route.params.id
                        }" :content="feedback.form.content" width="700px" height="400px" @base64Html="base64Flie($event, 'feedback','content','form')"
									id="kind2" @on-content-change="onContentChange($event, 'feedback','content','form')"></kindeditor>
                    <!-- <Toolbar style="border-bottom: 1px solid #dfe2e8" :editor="feedback.editorRef" mode="default" />
                    <Editor class="w100" style="height: 400px; overflow-y: hidden" v-model="feedback.form.content"
                        :defaultConfig="{ placeholder: '请输入内容...' }" mode="default"
                        @onCreated="$event => feedback.editorRef = $event" /> -->
                </div>
            </li>
            <li class="padt20">
                <label for=""></label>
                <div class="">
                    <el-button @click="feedback.visible = false">取 消</el-button>
                    <el-button type="primary" :loading="feedback.submitLoading ?? false" @click="submitFeedBack">提
                        交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="contact.title" width="520px" v-model="contact.visible">
        <el-form :model="contact.form" ref="submitForm_contact" label-width="auto" :rules="contact.rule">
            <ul class="sylform " v-loading="contact.loading">
                <template v-if="contact.control == 1">
                    <li>
                        <label for="">主要联系人<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-input v-model="contact.form.mainContact" placeholder="请输入联系人" class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">手机号<em>*</em></label>
                        <el-form-item prop="mainMobile">
                            <el-input v-model="contact.form.mainMobile" placeholder="请输入手机号" class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">邮箱号<em>*</em></label>
                        <el-form-item prop="mainEmail">
                            <el-input v-model="contact.form.mainEmail" placeholder="请输入邮箱号" class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">联系方式<em>*</em></label>
                        <el-form-item prop="mode">
                            <!-- <el-select class="minw240" v-model="contact.form.mode" placeholder="请选择联系方式">
                                <el-option label="电子邮件" :value="1"></el-option>
                                <el-option label="短信" :value="2"></el-option>
                                <el-option label="站内信" :value="3"></el-option>
                            </el-select> -->
                            <el-radio-group v-model="contact.form.mode" >
                                <el-radio
                                    :value="1"
                                    label="电子邮件"
                                />
                                <el-radio
                                    :value="2"
                                    label="电话"
                                />
                            </el-radio-group>
                        </el-form-item>
                    </li>
                </template>
                <template v-else-if="contact.control == 2">
                    <li>
                        <label for="">备用联系人<em>*</em></label>
                        <el-form-item prop="standbyContact">
                            <el-input v-model="contact.form.standbyContact" placeholder="请输入联系人"
                                class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">手机号<em>*</em></label>
                        <el-form-item prop="standbyMobile">
                            <el-input v-model="contact.form.standbyMobile" placeholder="请输入手机号" class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">邮箱号<em>*</em></label>
                        <el-form-item prop="standbyEmail">
                            <el-input v-model="contact.form.standbyEmail" placeholder="请输入邮箱号" class="w240"></el-input>
                        </el-form-item>
                    </li>
                    <li>
                        <label for="">联系方式<em>*</em></label>
                        <el-form-item prop="spareMode">
                            <!-- <el-select class="minw240" v-model="contact.form.spareMode" placeholder="请选择联系方式">
                                <el-option label="电子邮件" :value="1"></el-option>
                                <el-option label="短信" :value="2"></el-option>
                                <el-option label="站内信" :value="3"></el-option>
                            </el-select> -->
                            <el-radio-group v-model="contact.form.spareMode" >
                                <el-radio
                                    :value="1"
                                    label="电子邮件"
                                />
                                <el-radio
                                    :value="2"
                                    label="电话"
                                />
                            </el-radio-group>
                        </el-form-item>
                    </li>
                </template>
                <li>
                    <label for=""></label>
                    <span class="padt20 ">
                        <el-button @click="contact.visible = false">取 消</el-button>
                        <el-button type="primary" @click="submitContact">提 交</el-button>
                    </span>
                </li>
            </ul>
        </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :title="'编辑'+editData.title" width="520px" v-model="editData.show" @open.once="operatingSystemList">
        <el-form :model="contact.form" ref="submitForm_contact" label-width="auto" :rules="contact.rule">
            <ul class="sylform " v-loading="contact.loading">
                    <li v-if="editData.type == 1">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-select v-model="editData.form.hardwarePlatform" class="w242" placeholder="选择硬件平台" clearable>
                                <el-option v-for="item in hardwarePlatform" :key="item.dictValue"
                                    :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 2">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-cascader
									v-model="editData.form.operatingSystemVersionId"
									size="large"
									class="w100"
									:props="vselect.system.props"
									:options="vselect.system.options"
									placeholder="请选择"
								/>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 3">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-select v-model="editData.form.operatingSystemLanguage" class="w242" placeholder="选择语言" clearable>
                                <el-option v-for="item in language" :key="item.dictValue"
                                    :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 4">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-select v-model="editData.form.productId" class="w242" placeholder="选择产品" clearable>
                                <el-option v-for="item in vselect.product.options" :key="item.id"
                                    :label="item.productName" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 5">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-select v-model="editData.form.versionId" class="w242" placeholder="选择版本" clearable>
                                <el-option v-for="item in vselect.version.map_options.get(info.productId)" :key="item.id"
                                    :label="item.versionName" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 6">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <!-- <el-select v-model="editData.form.productModuleId" class="w242" placeholder="选择问题类型" clearable>
                                <el-option v-for="item in vselect.module.map_options.get(info.productId)" :key="item.id"
                                    :label="item.moduleName" :value="item.id" />
                            </el-select> -->
                            <el-cascader
                                v-model="editData.form.productModuleId"
                                class="w242"
                                clearable
                                :props="{
                                    checkStrictly: false,
                                    emitPath: false,
                                    value: 'id',
                                    label: 'moduleName',
                                    children: 'moduleListDtos'
                                }"
                                :options="vselect.module.map_options.get(info.productId)"
                                placeholder="选择问题类型"
                            >
                            </el-cascader>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 7">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="mainContact">
                            <el-select v-model="editData.form.deploymentWay" class="w242" placeholder="选择部署方式" clearable>
                                <el-option v-for="item in deploy" :key="item.dictValue"
                                    :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </el-form-item>
                    </li>
                    <li v-else-if="editData.type == 8">
                        <label>{{editData.title}}<em>*</em></label>
                        <el-form-item prop="oneMachine">
                            <el-radio-group v-model="editData.form.oneMachine">
                                <el-radio :value="1">是</el-radio>
                                <el-radio :value="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </li>
                <li>
                    <label for=""></label>
                    <span class="padt20 ">
                        <el-button @click="clickEditOff">取 消</el-button>
                        <el-button type="primary" @click="editSub">提 交</el-button>
                    </span>
                </li>
            </ul>
        </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="确认结单" width="685px" v-model="finish.show">
        <p class="col65 padl20">确认结单后，您将无法对该工单进行问题补充或留言反馈</p>
        <el-form :model="finish.form" ref="submitForm_finish" label-width="auto" :rules="finish.rule">
            <ul class="sylform padt30">
                <li>
                    <label for="">关闭工单理由</label>
                    <el-form-item prop="closeReason">
                        <el-radio-group v-model="finish.form.closeReason" class="w100">
                            <el-radio value="方案已提供">方案已提供</el-radio>
                            <el-radio value="已自行解决">已自行解决</el-radio>
                            <el-radio value="放弃解决">放弃解决</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </li>
                <li class="li1">
                    <label for="">结单反馈</label>
                    <el-form-item prop="closeFeedback">
                        <el-input v-model="finish.form.closeFeedback" type="textarea" :rows="6" placeholder="输入结单反馈"
                            class="w515" size="small"></el-input>
                    </el-form-item>
                </li>
            </ul>
        </el-form>
        <template #footer>
            <el-button @click="finish.show = false">取 消</el-button>
            <el-button :loading="finish.loading" type="primary" @click="submitFinishOrder">确 定</el-button>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="等级调整" width="685px" align-center v-model="upgrade.visible">
        <p class="col65 padl20">确认要对你的工单等级进行调整么？调整后技术支持将会调整，从而对你的工单进行解决</p>
        <ul class="sylform labw120 padt30">
            <li>
                <label for="">选择要调整的等级</label>
                <el-radio-group v-model="upgrade.form.u" class="w100">
                    <el-radio class="padb20" :label="1">U1-服务完全丢失</el-radio>
                    <el-radio :label="2" class="padb20">U2-服务严重丢失</el-radio>
                    <el-radio :label="3" class="padb20">U3-服务少量丢失</el-radio>
                    <el-radio :label="4" class="padb20">U4-未丢失服务</el-radio>
                </el-radio-group>
            </li>
            <template v-if="upgrade.form.u == 1">
                <p class="padb20 padl20 padt10"><span class="col24 fontw6">添加备用联系人联系方式</span> &nbsp;&nbsp;<span
                        class="colred font10">注：由于您的工单等级是U1严重需要添加备用联系人</span> </p>
                <li>
                    <label for="">备用联系人<em>*</em></label>
                    <el-input v-model="upgrade.form.standbyContact" placeholder="请输入联系人姓名" class="w240"></el-input>
                </li>
                <li class="mart20">
                    <label for="">手机号<em>*</em></label>
                    <el-input v-model="upgrade.form.standbyMobile" placeholder="请输入手机号" class="w240"></el-input>
                </li>
                <li class="mart20">
                    <label for="">邮箱号<em>*</em></label>
                    <el-input v-model="upgrade.form.standbyEmail" placeholder="请输入邮箱号" class="w240"></el-input>
                </li>
            </template>
        </ul>
        <template #footer>
            <div class="tl padb30" style="padding-left:150px">
                <el-button @click="upgrade.visible = false">取 消</el-button>
                <el-button type="primary" :loading="upgrade.submitLoading ?? false" @click="submitUpgrade">提
                    交</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="工单升级" align-center width="685px" v-model="upgrade_order.visible">
        <p class="col65 padl20">确认要对你的工单进行升级么？升级后技术支持将会升级，从而对你的工单进行解决</p>
        <ul class="sylform  padt30">
            <li class="li1">
                <label for="">升级原因</label>
                <el-input v-model="upgrade_order.form.upgradeReason" type="textarea" :rows="6"
                    placeholder="输入要进行工单升级的原因" class="w515" size="small"></el-input>
            </li>
            <li>
                <label for=""></label>
                <div class="tl padt20">
                    <el-button @click="upgrade_order.visible = false">取 消</el-button>
                    <el-button type="primary" @click="submitOrderUpgrade">提 交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="documentType == 1 ? '工单相关知识文档' : '工单相关Bug文档'" v-model="files">
        <el-table ref="links" :data="documentL" :header-cell-style="{ background: '#F1F1F1' }" height="450"
            class="border fujian" style="width: 100%">
            <el-table-column label="标题" prop="title" class-name="padl20 padr20" min-width="160" show-overflow-tooltip>
                <template #default="scope">
                    <el-link :href="'#/knowledgeInfo/' + scope.row.id" target="_blank" style="display:unset"
                        class="text1 ">{{ scope.row.title }}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="上传时间" prop="createTime" min-width="100">
            </el-table-column>
            <el-table-column label="注释" prop="remake" min-width="160">
            </el-table-column>
        </el-table>

    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="相关链接" width="685px" v-model="links">
        <el-table ref="links" height="450" :data="info.linkListDtos" :header-cell-style="{ background: '#F1F1F1' }"
            class="border fujian" style="width: 100%">
            <el-table-column prop="address" label="链接地址" class-name="padl20 padr20" show-overflow-tooltip>
                <template #="{ row }">
                    <el-link :href="row.url" target="_blank" style="display:unset" class="text1 ">{{ row.url
                        }}</el-link>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="相关附件" width="685px" v-model="info.attachment_visible">
        <el-table height="450" :data="info.attachmentListDtos" :header-cell-style="{ background: '#F1F1F1' }"
            class="border fujian" style="width: 100%">
            <el-table-column label="附件地址" class-name="padl20 padr20" min-width="160">
                <template #="{ row }">
                    <p class="download-link" @click="downloadtxt(row.title)">{{ decodeURIComponent(row.title) }}</p>
                </template>
            </el-table-column>
            <el-table-column label="上传时间" prop="createTime" min-width="100">
            </el-table-column>
            <el-table-column label="注释" prop="remake" fixed="right" min-width="160">
            </el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="重开工单" width="685px" v-model="reOpen.show" @closed="(reOpen.form = {
                id: $route.params.id
            }) && $refs.submitForm_reOpen.resetFields()">
        <el-form :model="reOpen.form" ref="submitForm_reOpen" label-width="auto" :rules="reOpen.rule">
            <el-form-item prop="openAgainReason" label="重开工单原因">
                <el-input v-model="reOpen.form.openAgainReason" type="textarea" :rows="6" placeholder="输入原因"
                    class="w515" size="small"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="openAgainDesc" label="其它详细资料">
                <el-input v-model="reOpen.form.openAgainDesc" type="textarea" :rows="6" placeholder="请输入添加附件的注释"
                    class="w515" size="small"></el-input>
            </el-form-item> -->
        </el-form>
        <template #footer>
            <span class="dialog-footer ">
                <el-button @click="reOpen.show = false">取 消</el-button>
                <el-button :loading="reOpen.loading" type="primary" @click="submitReOpen">提 交</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="工单升级原因" width="700px" align-center v-model="info.upgradeReason_visible">
        <div>
            {{ info.upgradeReason }}
        </div>
        <template #footer>
            <div class="padt40 padb10">
                <el-button type="primary" @click="info.upgradeReason_visible = false">确 定</el-button>
            </div>
        </template>
    </el-dialog>
    <!-- 评价 -->
    <el-dialog :close-on-click-modal="false" title="服务评价" width="685px" v-model="comment.show" @closed="(comment.form = {
                orderId: $route.params.id
            }) && $refs.submitForm_comment.resetFields()">
        <p class="col65 padl20 marb40">您对我们的整体服务评价会让我们未来做得更好</p>
        <el-form :model="comment.form" ref="submitForm_comment" label-width="auto" :rules="comment.rule">
            <el-form-item prop="rank" label="整体评价">
                <el-rate v-model="comment.form.rank" size="large" :style="{
                '--el-rate-fill-color': '#FF6A00'
            }" />
            </el-form-item>
            <el-form-item prop="isSolve" label="问题是否解决">
                <el-radio-group v-model="comment.form.isSolve" class="w100">
                    <el-radio value="1">已解决</el-radio>
                    <el-radio value="0">未解决</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="content" label="文字反馈">
                <el-input v-model="comment.form.content" type="textarea" :rows="6" placeholder="输入您要反馈的内容" class="w515"
                    size="small"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="comment.show = false">取 消</el-button>
            <el-button :loading="comment.loading" type="primary" @click="submitComment">确 定</el-button>
        </template>
    </el-dialog>
</template>
<script>
import OSS from 'ali-oss'
import { uploadOssGetToken } from "@/utils/api1.js"
import { shallowRef, markRaw } from 'vue'
import { mapGetters } from 'vuex'
import { Back, Star, StarFilled } from '@element-plus/icons-vue'
import '@wangeditor/editor/dist/css/style.css'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
    setup() {
        return {
            mode: new Map([
                ['1', '电子邮件'],
                ['2', '电话'],
            ]),
            upgradeStatus: new Map([
                [0, '未升级'],
                [1, '已升级'],
            ]),
            userStatus: new Map([
                [1, '待分派'],
                [2, '支持工作中'],
                [3, '客户工作中'],
                [4, '复查更新'],
                [5, '研发工作中'],
                [6, '待研发反馈'],
                [7, '待内部反馈'],
                [8, '方案已提供'],
                [9, '用户请求关闭'],
                [10, '已关闭'],
                [11, '重开审核中'],
            ]),
            code: new Map([
                [0, '问题反馈'],
                [1, '客户问题描述'],
                [2, '客户已更新'],
                [3, 'VDM问题澄清'],
                [4, 'VDM问题证据'],
                [5, 'VDM问题原因'],
                [6, 'VDM诊断依据'],
                [7, 'VDM解决方案'],
                [8, 'VDM方案依据'],
                [9, 'VDM文档管理'],
                [10, 'VDM问题'],
                [11, '工程师回复'],
                [12, 'VDM行动计划'],
                [13, 'VDM数据收集'],
                [14, '电话拨出'],
                [15, '电话呼入'],
                [16, '更新'],
                [17, 'VDM分析研究'],
                [18, '邮件发送'],
                [19, 'VDM测试用例'],
            ]),
            // 7月25修改VDM
            /*code: new Map([
                [0, '问题反馈'],
                [1, '客户问题描述'],
                [2, '客户已更新'],
                [3, 'VDM问题澄清'],
                [4, '问题证据'],
                [5, '问题原因'],
                [6, '诊断依据'],
                [7, '解决方案'],
                [8, '方案依据'],
                [9, '文档管理'],
            ]),*/
            type: new Map([
                [1, '非技术工单'],
                [2, '技术工单'],
            ]),
            oneMachine: new Map([
                [1, '是'],
                [2, '否'],
            ]),
            isOpen: new Map([
                [1, '全部公开'],
                [2, '不公开'],
            ]),
            recordType: new Map([
                [1, '回复'],
                [2, '转单'],
                [3, '升级'],
                [4, '告警'],
                [5, '分裂'],
                [6, '调整u等级'],
                [7, '重新分配'],
                [12, '状态修改'],
            ]),
			u: markRaw({ 4: 'U4-服务未丢失', 3: 'U3-服务少量丢失', 2: 'U2-服务严重丢失', 1: 'U1-服务完全丢失' }),
            typeL:{3:'工单升级',5:'工单分裂',6:'调整u等级',7:'重新分配',8:'申请重开工单',11:'发起结单'},
        }
    },
    components: {
        Back,
        Star,
        StarFilled,
        // Editor,
        // Toolbar,
    },
    computed: {
        ...mapGetters({
            hardwarePlatform: 'cache/getHardwarePlatform',
            language: 'cache/getLanguage',
            deploy: 'cache/getDeploy',
        }),
    },
    data() {
        return {
            scroll: '',
            editData: {
                show: false, type: '', title: '', form: {}
            },
            fullscreenLoading: false,
            loading: false,
            id: this.$route.params.id,
            vselect: {
                team: {
                    loading: false,
                    map_options: new Map()
                },
                product: {
                    loading: false,
                    options: [],
                },
                module: {
                    loading: false,
                    map_options: new Map()
                },
                version: {
                    loading: false,
                    map_options: new Map()
                },
                system: {
                    props: {
                        checkStrictly: false,
                        emitPath: true,
                        value: 'id',
                        label: 'title',
                        children: 'list'
                    },
                    options: []
                }
            },
            info: {
                loading: false,
                attachmentListDtos: [],  //附件
                bugListDtos: [],  //相关bug文档
                docListDtos: [],  //相关知识文档
                linkListDtos: [],  //相关链接
            },
            record: {
                param: {
                    showIsType: 1,
                    isType: 1,
                    orderId: this.$route.params.id,
                    pageNumber: 1,
                    pageSize: 9999,
                    sort:'2'
                },
                loading: false,
                list: [],
                total: 0
            },
            param: {
                workOrderId: this.$route.params.id,
            },
            detail: {
                attachmentListDtos: [],
                problemImages: [],
                attachmentPath: []
            },
            documentType: '', //文档类型
            documentL: [], //文档列表
            edit: false,  //编辑联系人信息
            files: false,  //工单相关知识文档
            links: false,   //相关链接
            reOpen: {
                show: false,
                form: {
                    id: this.$route.params.id,
                },
                rule: {
                    openAgainReason: [{ required: true, message: '选择原因', trigger: 'blur' }],
                    // openAgainDesc: [{ required: true, message: '请输入添加附件的注释', trigger: 'blur' }],
                }
            },
            // 工单等级调整
            upgrade: {
                visible: false,
                submitLoading: false,
                form: {
                    id: this.$route.params.id
                }
            },
            // 工单反馈
            feedback: {
                visible: false,
                editorRef: shallowRef(),
                submitLoading: false,
                form: {
                    orderId: this.$route.params.id,
                    content: ''
                }
            },
            // 附件添加
            attachment: {
                visible: false,
                loading: false,
                form: {
                    orderId: this.$route.params.id,
                    attachmentPath: '',
                    attachmentPathType:2,
                    attachmentDescription: ''
                }
            },
            // 升级工单
            upgrade_order: {
                visible: false,
                loading: false,
                form: {
                    id: this.$route.params.id,
                    upgradeReason: ''
                },
            },
            // 联系人信息
            contact: {
                visible: false,
                title: '编辑联系人信息',
                loading: false,
                control: 1, // 主要联系人 2 备用联系人
                form: {
                    id: this.$route.params.id,
                    mainContact: '',
                    mainEmail: '',
                    mainMobile: '',
                    mode: [],
                    standbyContact: '',
                    standbyEmail: '',
                    standbyMobile: '',
                    spareMode: [],
                },
                rule: {
                    mainContact: [{ required: true, message: '请输入主要联系人', trigger: 'blur' }],
                    mainEmail: [{ required: true, message: '请输入联系人邮箱', trigger: 'blur' }],
                    mainMobile: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
                    mode: [{ required: true, message: '请选择联系方式', trigger: 'change' }],
                    standbyContact: [{ required: true, message: '请输入备用联系人', trigger: 'blur' }],
                    standbyEmail: [{ required: true, message: '请输入联系人邮箱', trigger: 'blur' }],
                    standbyMobile: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
                    spareMode: [{ required: true, message: '请选择联系方式', trigger: 'change' }],
                }
            },
            // 查阅权限
            isOpenLook: {
                visible: false,
                loading: false,
                form: {
                    departmentId: []
                },
                rule: {
                    isOpen: [{ required: true, message: '请选择是否公开！', trigger: 'change' }],
                    departmentId: [
                        {
                            validator: (rule, value, callback) => {
                                if (value.length) {
                                    return callback()
                                }
                                return callback(new Error('请选择团队部门'))
                            }, trigger: 'blur'
                        },
                    ],
                }
            },
            fileList: {
                instance_attachment: null,
                attachment: [],
            },
            finish: {
                show: false,
                loading: false,
                form: {
                    id: this.$route.params.id
                },
                rule: {
                    closeReason: [{ required: true, message: '请输入关闭理由', trigger: 'blur' }],
                    closeFeedback: [{ required: true, message: '请输入结单反馈', trigger: 'blur' }],
                }
            },
            comment: {
                show: false,
                loading: false,
                form: {
                    orderId: this.$route.params.id
                },
                rule: {
                    isSolve: [{ required: true, message: '请选择是否解决', trigger: 'blur' }],
                    rank: [{ required: true, message: '请评分', trigger: 'blur' }],
                    content: [{ required: true, message: '请输入反馈内容', trigger: 'blur' }],
                }
            }
        }
    },
    mounted() {
        this.getData();
        this.getOrderRecordListDto()
        this.vselect_product()
        this.$store.dispatch('cache/getHardwarePlatformList')
        this.$store.dispatch('cache/getLanguage')
        this.$store.dispatch('cache/getDeploy')
        window.scrollTo(0,0)
    },
    methods: {
        hadhttprequest(content){
            let that = this
            console.log('content',content)
            this.attachment.form.attachmentPathType = true
            uploadOssGetToken().then(res1 => {
                if (res1) {
                    that.sts = res1;
                    console.log(that.sts, 'token77777');
                    that.getOssToken(res1, content);
                }
            });
        },
        //直接向阿里云上传阿里云
        async getOssToken(data, content) {
            let that = this;
            // 上传到阿里云视频文件路径名称
            let videoName = Date.parse(new Date()) / 1000 + content.file.name;
            let client = new OSS({
                region: 'oss-cn-beijing',
                secure: true,
                accessKeyId: data.ossAccessKeyId,
                accessKeySecret: data.accessKeySecret,
                stsToken: data.securityToken,
                endpoint: "oss-cn-beijing.aliyuncs.com",
                bucket: 'bucket-mvs'
            });
            try {
                console.log('文件', content.file)
                //上传
                let result = await client.multipartUpload(videoName, content.file, {});
                console.log(result, "上传返回")
                this.attachment.form.attachmentPathType = false
                // this.editData.headPic = result.res.requestUrls[0]
                this.$message.success('上传成功')
                this.attachment.form['attachmentPath'] = result.res.requestUrls[0].split('?uploadId=')[0]
            } catch (e) {
                console.log('失败', e)
                if (e.code === 'ConnectionTimeoutError') {
                    content.onError('视频上传超时')
                    throw "视频上传超时!";
                } else {
                    content.onError('视频上传失败')
                }
            }
        },
        base64Flie (base64,key1,key2,key3) {
            if(key3){
                this[key1][key3][key2] +=base64
            }else{
                this[key1][key2] +=base64
            }
        },
		onContentChange (val,key1,key2,key3) {
            let that = this
            if (val.includes(';base64')) {
				let start = `<img src="`
				let end = `" alt="" />`
				const regex = new RegExp(`${start}(.*?)${end}`);
				const result = regex.exec(val);
				const substr = result ? result[1] : ""; // 如果有匹配到结果则取第一个分组的内容
				console.log('截取',substr);
				uploadOssGetToken().then(res1 => {
					console.log('获取oss参数',res1)
					if (res1) {
						that.uploadToOSS(res1, substr, key1, key2, key3,val.split(substr)[0],val.split(substr)[1])
					}
				});
			}else if(key3){
                this[key1][key3][key2] = val
            }else {
                this[key1][key2] = val
            }
        },
        
		async uploadToOSS(data,url, key1, key2, key3,p1,p2) {
			let that = this
			console.log('获取图片路径',url)
			function dataURLtoBlob(dataurl) {
				const arr = dataurl.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new Blob([u8arr], { type: mime });
			}
			const blob = dataURLtoBlob(url)
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const filePath = `uploads${year}${month}${day}`; 
			let file = new File([blob], filePath, {type: blob.type});
			console.log('filefilefile',file)
			let client = new OSS({
                region: 'oss-cn-beijing',
                secure: true,
                accessKeyId: data.ossAccessKeyId,
                accessKeySecret: data.accessKeySecret,
                stsToken: data.securityToken,
                endpoint: "oss-cn-beijing.aliyuncs.com",
                bucket: 'bucket-mvs'
            });
			try {
                console.log('文件', file)
                //上传
                let result = await client.multipartUpload(Date.parse(new Date()) / 1000 + filePath, file, {});
				let url = result.res.requestUrls[0].split('?uploadId=')[0]
                console.log('上传成功返回',result)
				if (key3) {
					that[key1][key3][key2] = p1 + url + p2
				} else {
					that[key1][key2] = p1 + url + p2 
				}
                // that.file.url = result.res.requestUrls[0]
                // that.fileList.attachment['attachmentPath'] = result.res.requestUrls[0]
            } catch (e) {
                console.log('失败', e)
                if (e.code === 'ConnectionTimeoutError') {
                    content.onError('视频上传超时')
                    throw "视频上传超时!";
                } else {
                    content.onError('视频上传失败')
                }
            }
		},
        // 重开工单
        async submitReOpen() {
            try {
                await this.$refs['submitForm_reOpen'].validate()
                this.reOpen.loading = true
                const result = await this.$api.order.orderOpenAgain(this.reOpen.form)
                if (result) {
                    this.reOpen.show = false
                    this.getData()
                    this.getOrderRecordListDto()
                    this.$message.success(result.message)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.reOpen.loading = false
            }
        },
        // 查阅权限修改
        async submitIsOpen() {
            try {
                await this.$refs['submitForm_IsOpen'].validate()
                this.isOpenLook.loading = true
                const _data = {
                    id: this.isOpenLook.form.id,
                    isOpen: this.isOpenLook.form.isOpen,
                }
                if (_data.isOpen == 2) {
                    _data.departmentId = this.isOpenLook.form.departmentId.join(',')
                } else if (_data.isOpen == 1) {
                    _data.departmentId = ''
                }
                const result = await this.$api.order.editOrderInfo(_data)
                if (result) {
                    this.isOpenLook.visible = false
                    this.getData()
                    this.getOrderRecordListDto()
                    this.$message.success(result.message)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.isOpenLook.loading = false
            }
        },
        // 服务评价
        async submitComment() {
            try {
                await this.$refs['submitForm_comment'].validate()
                this.comment.loading = true
                const result = await this.$api.order.subOrderCommentReq(this.comment.form)
                if (result) {
                    this.comment.show = false
                    this.getData()
                    this.getOrderRecordListDto()
                    this.$message.success(result.message)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.comment.loading = false
            }
        },
        toRecord() {
            this.record.param.isType = 1
            this.getOrderRecordListDto()
        },
        toOperation() {
            this.record.param.code = ''
            this.record.param.isType = 2
            this.getOrderRecordListDto()
        },
        async editSub(){
            let that = this
            let form = JSON.parse(JSON.stringify(that.editData.form))
            console.log('form.operatingSystemVersionId',form)
            if(form.operatingSystemVersionId){//如果有操作系统版本号的话  这个是个数组   需要获取下面的id
                form.operatingSystemVersionId = form.operatingSystemVersionId[1]||form.operatingSystemVersionId[0]
            }
            that.$api.order.editOrderInfo(form).then(res=>{
                if(res){
                    that.$message.success(res.message)
                    that.getData() 
                    that.clickEditOff()
                }
            })
            
        },
        async clickEdit(type, title) {
            let that = this
            that.editData.show = true
            that.editData.type = type
            that.editData.title = title
            let info = that.info
            that.editData.form = {id: that.$route.params.id}
            if (type == 1) {//硬件平台
                that.editData.form.hardwarePlatform = info.hardwarePlatform
            }else if(type==2){//操作系统
                that.editData.form.operatingSystemVersionId = info.operatingSystemVersionId
            }else if (type == 3) {//操作系统语言
                that.editData.form.operatingSystemLanguage = info.operatingSystemLanguage
            }else if (type == 4) {//产品名称
                that.editData.form.productId = info.productId
            }else if (type == 5) {//产品版本
                that.editData.form.versionId = info.versionId
            }else if (type == 6) {//问题类型
                that.editData.form.productModuleId = info.productModuleId
            }else if (type == 7) {//部署方式
                that.editData.form.deploymentWay = info.deploymentWay
            }else if (type == 8) {//是否为海量一体机
                that.editData.form.oneMachine = info.oneMachine
            }
        },
        clickEditOff(){
            this.editData = {
                show:false,type:'',title:'',form:{}
            }
        },
        editContact(control, title) {
            this.contact.visible = true
            this.contact.title = title
            this.contact.form = {
                id: this.$route.params.id,
                mainContact: this.info.mainContact,
                mainEmail: this.info.mainEmail,
                mainMobile: this.info.mainMobile,
                mode: this.info.mode,
                standbyContact: this.info.standbyContact,
                standbyEmail: this.info.standbyEmail,
                standbyMobile: this.info.standbyMobile,
                spareMode: this.info.spareMode,
            }
        },
        editIsOpen() {
            this.isOpenLook.visible = true
            this.vselect_team(this.info.csiId).then(() => {

                this.isOpenLook.form = {
                    id: this.$route.params.id,
                    isOpen: this.info.isOpen,
                    departmentId: this.info.departmentId ? this.info.departmentId.split(',') : []
                }
            })
        },
        async vselect_team(csiId) {
            try {
                this.vselect.team.loading = true
                this.vselect.team.map_options.set(
                    csiId,
                    this.vselect.team.map_options.get(csiId) ?? (
                        await this.$api.user.getDepartmentListByCsiId({
                            id: csiId,
                            pageNumber: 1,
                            pageSize: 2000
                        })
                    ).records
                )
                this.vselect.team.loading = false
                return Promise.resolve()
            } catch (e) {
                this.vselect.team.loading = false
                this.vselect.team.options = []
                return Promise.reject()
            }
        },
        // 收藏工单
        async collectOrder(row) {
            try {
                row.disableLoading = true
                const result = await this.$api.order.collectOrder({ id: row.id })
                if (result) {
                    this.$message.success(result.message)
                    if (row.isCollect == 0) {
                        row.isCollect = 1
                    } else if (row.isCollect == 1) {
                        row.isCollect = 0
                    }
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.disableLoading = false
            }
        },
        // 获取记录
        async getOrderRecordListDto(pageNumber) {
            try {
                pageNumber && (this.record.param.pageNumber = pageNumber)
                this.record.loading = true
                const param = {
                    sort: this.record.param.sort,
                    codeFlag: this.record.param.codeFlag,
                    isType: this.record.param.isType,
                    orderId: this.record.param.orderId,
                    pageNumber: this.record.param.pageNumber,
                    pageSize: this.record.param.pageSize
                }
                const { records, total } = await this.$api.order.getOrderRecordListDto(param)
                // 加工图片
                this.record.list = records.map(item => {
                    if (item.attachmentPath) {
                        item.attachmentPath = item.attachmentPath.split(',')
                    }
                    return item
                })

                this.record.param.showIsType = this.record.param.isType
                this.record.total = total
                console.log(records,'record.list')
                return Promise.resolve()
            } catch (e) {
                return Promise.reject(e)
            } finally {
                this.record.loading = false
            }
        },
        // 结单提交
        async submitFinishOrder() {
            try {
                await this.$refs['submitForm_finish'].validate()
                this.finish.loading = true
                const result = await this.$api.order.finishOrder(this.finish.form)
                if (result) {
                    this.finish.show = false
                    this.$message.success(result.message)
                    // 提交附件成功重载详情和沟通记录
                    this.getData()
                    this.getOrderRecordListDto()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.finish.loading = false
            }
        },
        openfiles(type) {
            this.documentType = type;
            if (type == 1) {
                this.documentL = this.info.docListDtos;
            } else if (type == 2) {
                this.documentL = this.info.bugListDtos;
            };
            this.files = true;
        },
        // 升级工单, key有冲突
        async submitContact() {
            try {
                await this.$refs['submitForm_contact'].validate()
                this.contact.loading = true
                const _data = this.contact.form
                _data.mode = _data.mode
                _data.spareMode = _data.spareMode
                const result = await this.$api.order.editOrderContact(this.contact.form)
                if (result) {
                    this.contact.visible = false
                    this.$message.success(result.message)
                    // 提交附件成功重载详情和沟通记录
                    this.getData()
                    this.getOrderRecordListDto()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.contact.loading = false
            }
        },
        // 升级工单
        async submitOrderUpgrade() {
            try {
                if (!this.upgrade_order.form.upgradeReason) {
                    return this.$message.warning('输入要进行工单升级的原因')
                }
                this.upgrade_order.loading = true
                const result = await this.$api.order.orderUpgrade(this.upgrade_order.form)
                if (result) {
                    this.upgrade_order.visible = false
                    this.$message.success(result.message)
                    // 提交附件成功重载详情和沟通记录
                    this.getData()
                    this.getOrderRecordListDto()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.upgrade_order.loading = false
            }
        },
        // 提交附件
        async submitAttachment() {
            try {
                this.attachment.loading = true
                if (!this.attachment.form.attachmentPath) {
                    if(this.attachment.form.attachmentPathType){
                        return this.$message.warning('文件上传中，请稍后')
                    }else{
                        return this.$message.warning('请上传附件')
                    }
                }
                const result = await this.$api.order.insertOrderAttachmentReq(this.attachment.form)
                if (result) {
                    this.attachment.visible = false
                    this.$message.success(result.message)
                    // 提交附件成功重载详情和沟通记录
                    this.getData()
                    this.getOrderRecordListDto()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.attachment.loading = false
            }
        },
        // 添加工单反馈提交
        async submitFeedBack() {
            try {
                // if (!this.feedback.editorRef.getText()) {
                //     return this.$message.warning('请输入反馈内容！');
                // }
                this.feedback.submitLoading = true
                const result = await this.$api.order.insertOrderFeedback({
                    orderId: this.feedback.form.orderId,
                }, {
                    content: this.feedback.form.content
                })
                if (result) {
                    this.feedback.visible = false
                    this.$message.success(result.message)
                    // 反馈成功重载沟通记录
                    this.getOrderRecordListDto()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.feedback.submitLoading = false
            }
        },
        // 等级调整提交
        async submitUpgrade() {
            try {
                if (this.upgrade.form.u == 1) {
                    if (!this.upgrade.form.standbyContact) {
                        this.$message.warning('请输入备用联系人！');
                        return;
                    } else if (!this.upgrade.form.standbyMobile) {
                        this.$message.warning('请输入备用联系人手机号！');
                        return;
                    } else if (!this.upgrade.form.standbyEmail) {
                        this.$message.warning('请输入备用联系人邮箱号！');
                        return;
                    }
                }
                this.upgrade.submitLoading = true
                const result = await this.$api.order.editOrderU(this.upgrade.form)
                if (result) {
                    this.upgrade.visible = false
                    this.getData()
                    this.getOrderRecordListDto()
                    this.$message.success(result.message)
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.upgrade.submitLoading = false
            }
        },
        downloadtxt(path, name) {
            let fileSuffix = path.split('.').pop();//文件名后缀。
            let filename = decodeURIComponent(path.split('/').pop());
            fetch(path).then(async (res) => {
                let blob = await res.blob()
                let url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('download', filename)
                a.setAttribute('href', url)
                a.style.display = 'none'
                document.body.append(a)
                a.target = '_blank'
                a.click()
                document.body.removeChild(a)
            })
        },
        async getData() {
            try {
                this.fullscreenLoading = true
                this.info = await this.$api.order.getOrderDetailDto({ id: this.$route.params.id })
                this.vselect_module(this.info.productId)
                this.vselect_version(this.info.productId)
                this.upgrade.form.u = this.info.u
            } catch (e) {
                return console.log(e)
            } finally {
                this.fullscreenLoading = false
            }
        },
        async operatingSystemList() {
            try {
                const { records } = await this.$api.base.operatingSystemList({
                    pageNumber: 1,
                    pageSize: 2000,
                })
                this.vselect.system.options = records
            } catch (e) {
                return console.log(e)
            }
        },
        async vselect_product() {
            try {
                this.vselect.product.loading = true
                const { records, total } = await this.$api.bug.productList({
                    pageNumber: 1,
                    pageSize: 1500
                })
                this.vselect.product.options = records.map(item => {
                    item.moduleName = item.productName
                    return item
                })
                this.vselect.product.loading = false
            } catch (e) {
                this.vselect.product.loading = false
                this.vselect.product.options = []
            }
        },
        async vselect_module(id) {
            try {
                this.vselect.module.loading = true
                this.vselect.module.map_options.set(
                    id,
                    this.vselect.module.map_options.get(id) ?? (
                        await this.$api.bug.moduleList({
                            id,
                            pageNumber: 1,
                            pageSize: 2000
                        })
                    ).records
                )
                this.vselect.module.loading = false
            } catch (e) {
                this.vselect.module.loading = false
                this.vselect.module.options = []
            }
        },
        async vselect_version(id) {
            try {
                this.vselect.version.loading = true
                this.vselect.version.map_options.set(
                    id,
                    this.vselect.version.map_options.get(id) ?? (
                        await this.$api.bug.versionList({
                            productId: id,
                            pageNumber: 1,
                            pageSize: 2000
                        })
                    ).records
                )
                this.vselect.version.loading = false
            } catch (e) {
                this.vselect.version.loading = false
                this.vselect.version.options = []
            }
        },
        handleScroll() {
            this.scroll = window.pagesYoffset || document.documentElement.scrollTop || document.body.scrollTop;
            console.log(this.scroll);
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        this.feedback.editorRef && this.feedback.editorRef.destroy()
    },
}
</script>
<style lang="scss" scoped>
.download-link {
    transition: .2s;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: #FF6A00;
        transition: .2s;
    }
}

.g-tabsb1 {
    margin-bottom: unset;

    .con {
        padding: 20px 5px;
    }
}

.left {
    width: calc(100% - 337px);
}

.vhtml {
    width: 100%;
    overflow-x: auto;
}

.vhtml img {
    width: 100%;
}

.minw240 {
    min-width: 240px;
}

.ul1 {
    font-size: 12px;
    color: #222222;
    padding: 30px 0 16px 0;
    border-top: 1px dashed #D7D6D6;

    li {
        >span:nth-child(1) {
            color: #9A9A9A;
            display: inline-block;
            width: 72px;
            min-width: 72px;
            text-align: right;
            padding-right: 25px;
        }

        padding-bottom: 14px;
    }

}

.right {
    min-width: 337px;
    width: 337px;
    box-sizing: border-box;
    margin-left: 20px;
    background: #fff;
    padding: 22px 16px;
}

.mat-1 {
   // margin-top: -4px;
    display: inline-block;
    cursor: pointer;
}

.borbotd {
    border-bottom: 1px dashed #D7D6D6;
}

.hiddenInput {
    opacity: 0;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.navpl .m-detailsl .top.fixed .box {
    padding-left: 83px;
}
.top {
    overflow: hidden;
    display:flex;
    margin-bottom: 24px;
    height: 30px;
    justify-content: space-between;

    .box {
        overflow: hidden;

        .date {
            float: right;
            overflow: hidden;
            margin-left: 46px;
            font-size: 12px;
            line-height: 20px;
            color: #242424;

            .icon {
                margin-right: 10px;
                float: left;
            }
        }

        .tit {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            line-height: 20px;
            color: #242424;
            font-weight: bold;
        }
    }
}
.top.fixed {
    .box {
        position: fixed;
        left: 0;
        right: 0;
        top: 120px;
        z-index: 8;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px 0px #E7E7E7;
        border: 1px solid #F0F0F0;
        padding: 15px 20px 15px 260px;
    }
}
</style>